import http from '../instance'

interface IVideoViewing {
  currentTimePoint: string
  duration: string
  percentCompletion: number
}

interface IVideoViewingResult {
  videoViewing: IVideoViewing | null
}

export const getActiveMaterialContent = (data: { materialId: number }) =>
  http.httpCore.get('material/content/active', { params: data })

export const deleteMaterialContent = (data: { materialContentId: number }) =>
  http.httpCore.delete(`material/content/${data.materialContentId}`)

export const getIdMaterialContent = (data: {
  id: number
  byMaterialId: boolean
}) => http.httpCore.get(`material/content/index`, { params: data })

export const getBasicInformationCourse = (data: {
  materialContentId: number
}) => http.httpCore.get(`course/${data.materialContentId}/information`)

export const getStatisticsCourses = (materialId: number, userId?: string) =>
  http.httpCore.get(`material/content/${materialId}/statistics/${userId}`)

export const getVideoViewing = async (data: { materialId: number | string }) =>
  await http.httpCore
    .get<IVideoViewingResult>(`content/video/viewing`, { params: data })
    .then((res) => res.data)

export const setVideoViewing = async (data: {
  materialId: number | string
  duration: number | string
  currentTimePoint: number | string
}) =>
  await http.httpCore
    .post(`content/video/viewing`, data)
    .then((res) => res.data)
