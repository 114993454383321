export const AUTH = 'AUTH'

export interface IAuthUser {
  userId: string
  email: string
  fullName: string
}

export interface IAuth {
  authUser: IAuthUser | null
  userPositions: IUserPosition[]
  access: boolean
  accessDesc: string
  closedLogin: boolean
  checkingLogin: boolean
}

export interface IUserPosition {
  name: string
  positionId: number
  value: number
}
