import { fetchFilterLearnMaterials } from './learnMaterialFilterThunks'
import { IFilterMaterial } from './learnMaterialFilterTypes'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface LearnState {
  filterMaterials: IFilterMaterial[]
  pageMaterial: number
  pageSizeMaterial: number
  totalPagesMaterial: number
  isLoading: boolean
  error: string
}

const initialState: LearnState = {
  filterMaterials: [],
  pageMaterial: 1,
  pageSizeMaterial: 20,
  totalPagesMaterial: 1,
  isLoading: false,
  error: '',
}

export const learnFilterMaterialSlice = createSlice({
  name: 'learn-materials-filter',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchFilterLearnMaterials.fulfilled.type]: (
      state,
      action: PayloadAction<any>
    ) => {
      state.isLoading = false
      state.error = ''
      state.filterMaterials = action.payload.materials
      state.pageMaterial = action.payload.pageNumber
      state.pageSizeMaterial = action.payload.pageSize
      state.totalPagesMaterial = action.payload.totalPages
    },
    [fetchFilterLearnMaterials.pending.type]: (state) => {
      state.isLoading = true
    },
    [fetchFilterLearnMaterials.rejected.type]: (
      state,
      action: PayloadAction<string>
    ) => {
      state.isLoading = false
      state.error = action.payload
      state.filterMaterials = []
      state.pageMaterial = 0
      state.totalPagesMaterial = 0
    },
  },
})

export const learnFilterMaterialReducer = learnFilterMaterialSlice.reducer
