import { createSlice } from '@reduxjs/toolkit'

type SelectedFolderProps = {
  name: string
  id?: number
}

interface BreadcrumbsProps {
  breadcrumbs: string
  selectedProject: any
  selectedFolder: SelectedFolderProps
  selectedTabProject: any
  selectedScormLink: any
}

const initialState: BreadcrumbsProps = {
  breadcrumbs: 'Главная',
  selectedProject: { id: 0, name: 'Все материалы' },
  selectedFolder: { name: 'Все материалы' },
  selectedTabProject: 0,
  selectedScormLink: {},
}

export const breadcrumbsSlice = createSlice({
  name: 'breadcrumbs',
  initialState,
  reducers: {
    breadcrumbsChange(state, action) {
      state.breadcrumbs = action.payload
    },
    selectedProjectChange(state, action) {
      state.selectedProject = action.payload
    },
    selectedFolderChange(state, action) {
      state.selectedFolder = action.payload
    },
    selectedTabProjectChange(state, action) {
      state.selectedTabProject = action.payload
    },
    selectedScormLinkChange(state, action) {
      state.selectedScormLink = action.payload
    },
  },
})

export default breadcrumbsSlice.reducer
export const {
  breadcrumbsChange,
  selectedProjectChange,
  selectedFolderChange,
  selectedTabProjectChange,
  selectedScormLinkChange,
} = breadcrumbsSlice.actions
