import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'
import { MATERIAL } from './learnMaterialTypes'
// import { uploadFile } from '../../api/requests/file'
import { getActiveMaterial } from '../../api/requests/material'

export const fetchLearnMaterials = createAsyncThunk(
  '/courses',
  async (params: any, thunkAPI) => {
    try {
      const response = await getActiveMaterial(params)
      return response.data.data
    } catch (e) {
      return thunkAPI.rejectWithValue('Не удалось загрузить уроки')
    }
  }
)

// export const uploadFileMaterial = createAsyncThunk<
//   any,
//   { file: any; materialId: any }
// >(`${MATERIAL}/uploadFile`, async (data, { rejectWithValue, dispatch }) => {
//   try {
//     return await uploadFile(data)
//   } catch (error: any) {
//     const err: AxiosError = error
//     return rejectWithValue(err.message)
//   }
// })
