import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { DataRange } from '../../entities/common/DateRange'
import { MateriaByCatalogIdsItem } from '../../entities/materials/MateriaByCatalogIdsItem'
import dayjs from 'dayjs'

interface FiltersState {
  CatatogIds: number[]
  MaterialIds: number[]
  MaterialValues: MateriaByCatalogIdsItem[]
  StartDate: string  // Change type to string
  EndDate: string    // Change type to string
  dateRange: string
  page: number
  pageSize: number
}

const initialState: FiltersState = {
  CatatogIds: [],
  MaterialIds: [],
  MaterialValues: [],
  StartDate: dayjs().subtract(1, 'month').startOf('day').toISOString(), // Use ISO string
  EndDate: dayjs().endOf('day').toISOString(), // Use ISO string
  dateRange: "month",
  page: 0,
  pageSize: 500
}

const myTeamFiltersSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    setCatalogsFilter: (state, action: PayloadAction<number[]>) => {
      state.CatatogIds = action.payload
    },
    addCatalogFilter: (state, action: PayloadAction<number>) => {
      if (!state.CatatogIds.includes(action.payload)) {
        state.CatatogIds.push(action.payload)
      }
    },
    deleteCatalogFilter: (state, action: PayloadAction<number>) => {
      state.CatatogIds = state.CatatogIds.filter((id) => id !== action.payload)
    },
    setMaterialsFilter: (state, action: PayloadAction<number[]>) => {
      state.MaterialIds = action.payload
    },
    setMaterialsValues: (state, action: PayloadAction<MateriaByCatalogIdsItem[]>) => {
      state.MaterialValues = action.payload
    },
    setDatesFilter: (state, action: PayloadAction<DataRange>) => {
      state.StartDate = action.payload.DateStart
      state.EndDate = action.payload.DateEnd
    },
    setRangeFilter: (state, action:PayloadAction<string>) => {
      state.dateRange = action.payload
    },
    setPageFilter: (state, action:PayloadAction<number>) => {
      state.page = action.payload
    },
    setPageSizeFilter: (state, action:PayloadAction<number>) => {
      state.pageSize = action.payload
    },
  },
})

export const {
  setCatalogsFilter,
  addCatalogFilter,
  deleteCatalogFilter,
  setMaterialsFilter,
  setDatesFilter,
  setRangeFilter,
  setPageFilter,
  setPageSizeFilter,
  setMaterialsValues
} = myTeamFiltersSlice.actions

export default myTeamFiltersSlice.reducer
