import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { fetchArrangements } from './arrangementThunks'
import IArrangement from './arrangementTypes'


interface ArrangementState {
  arrangement: IArrangement[]
  pageMaterial: number
  pageSizeMaterial: number
  totalPagesMaterial: number
  isLoading: boolean
  error: string
}

const initialState: ArrangementState = {
  arrangement: [],
  pageMaterial: 1,
  pageSizeMaterial: 20,
  totalPagesMaterial: 1,
  isLoading: false,
  error: '',
}

export const arrangementSlice = createSlice({
  name: 'arrangement',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchArrangements.fulfilled.type]: (
      state,
      action: PayloadAction<any>
    ) => {
      state.isLoading = false
      state.error = ''
      state.arrangement = action.payload.arrangement
      state.pageMaterial = action.payload.page
      state.pageSizeMaterial = action.payload.pageSize
      state.totalPagesMaterial = action.payload.totalPages
    },
    [fetchArrangements.pending.type]: (state) => {
      state.isLoading = true
    },
    [fetchArrangements.rejected.type]: (
      state,
      action: PayloadAction<string>
    ) => {
      state.isLoading = false
      state.error = action.payload
      state.arrangement = []
      state.pageMaterial = 0
      state.totalPagesMaterial = 0
    },
  },
})

export default arrangementSlice.reducer