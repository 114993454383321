import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface SelfRegSlice {
  path: { label: string; id: number }[]
}

const initialState: SelfRegSlice = {
  path: [],
}

export const selfRegSlice = createSlice({
  name: 'self-reg',
  initialState,
  reducers: {
    addPath(state, action) {
      state.path.push(action.payload)
    },
    popPath(state) {
      state.path.pop()
    },
    backToPath(state, action) {
      state.path = state.path.slice(0, action.payload)
    },
  },
})

export const selfRegActions = selfRegSlice.actions
export const selfRegReducer = selfRegSlice.reducer
