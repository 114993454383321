import {
  filterMaterialApi,
  FilterMaterialApiProps,
} from './../../api/requests/material'
import { createAsyncThunk } from '@reduxjs/toolkit'

export const fetchFilterLearnMaterials = createAsyncThunk(
  '/filter',
  async (data: FilterMaterialApiProps, thunkAPI) => {
    try {
      const response = await filterMaterialApi(data.filter, data)
      const responseFilter = response.data.data.materials
      return {...response.data.data, materials: responseFilter}
    } catch (e) {
      return thunkAPI.rejectWithValue('Не удалось загрузить материалы')
    }
  }
)
