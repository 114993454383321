import ReactDOM from 'react-dom/client'
import App from './App'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { store } from './store/store'
import { AuthIframe } from './components/AuthIframe/AuthIframe'
import { authController } from './api/instance'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <AuthIframe authControllerInstance={authController} />
      <App />
    </BrowserRouter>
  </Provider>
)
