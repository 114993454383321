import { createAsyncThunk } from '@reduxjs/toolkit'
import { getActiveMaterialContent } from '../../api/requests/contentMaterial'

export const fetchContentLearnMaterials = createAsyncThunk(
  '/content',
  async (params: any, thunkAPI) => {
    try {
      const response = await getActiveMaterialContent(params)
      return response.data.data
    } catch (e) {
      return thunkAPI.rejectWithValue('Не удалось загрузить материалы')
    }
  }
)
