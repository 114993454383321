const getEnvironmentVariables = () => {
  return {
    apiUrl: process.env.REACT_APP_DEV_API_URL,
    importFileUrl: process.env.REACT_APP_DEV_IMPORT_FILE,
    scormFileUrl: process.env.REACT_APP_DEV_SCORM,
    scormParserUrl: process.env.REACT_APP_DEV_SCORM_PARSER,
  }
}

export const { apiUrl, importFileUrl, scormFileUrl, scormParserUrl }: any =
  getEnvironmentVariables()
