import { combineReducers } from '@reduxjs/toolkit'
// reducers
import user from './user/userSlice'
import breadcrumbs from './breadCrumb/breacrumbsSlice'
import auth from './auth/authSlice'
import { learnMaterialsReducer as learnMaterial } from './learnMaterial/learnMaterialsSlice'
import learnMaterialContent from './learnMaterialContent/learnMaterialContentSlice'
import { learnFilterMaterialReducer as learnFilterMaterial } from './learnMaterialFilter/learnMaterialFilterSlice'
import arrangement from './Arrangement/arrangementSlice'
import { selfRegReducer } from './selfReg/selfRegSlice'
import myTeamFiltersReducer from './myTeam/myTeamFiltersSlice'

export const rootReducer = combineReducers({
  auth,
  user,
  breadcrumbs,
  learnMaterial,
  learnMaterialContent,
  learnFilterMaterial,
  arrangement,
  selfRegReducer,
  myTeamFilters: myTeamFiltersReducer,
})
