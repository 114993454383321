import http from '../instance'

export type GetActiveMaterialProps = {
  folderId?: number
  projectId?: number
  courseId?: number
  isKnowledge?: boolean
  parentId?: number
  pageNumber?: number
  pageSize?: number
}

export type CreateMaterialApiProps = {
  parentId?: number
  name: string
  appointment: boolean
  folderId?: any
}

export type FilterMaterialType =
  | 'selfRegistered'
  | 'appointed'
  | 'all'
  | 'available'
  | ''
  | '*'
  | undefined

export type FilterMaterialApiProps = {
  pageNumber?: number
  pageSize?: number
  catalogId?: number
  filter?: FilterMaterialType
}

export const getMaterialContent = (data: { folderId?: number }) =>
  http.httpCore.get('material', { data })

export const getActiveMaterial = (data: GetActiveMaterialProps) =>
  http.httpCore.get(`material/active`, { params: data })

export const createMaterialApi = (data: CreateMaterialApiProps) =>
  http.httpCore.post('material', data)

export const deleteMaterialApi = (data: { materialId: number }) =>
  http.httpCore.delete(`material/${data.materialId}`)

export const filterMaterialApi = (
  filter: FilterMaterialType,
  data: FilterMaterialApiProps
) =>
  http.httpCore.get(`material/courses/${filter}`, {
    params: data,
  })

export const getContentDashboard = (materialId: number) =>
  http.httpCore.get(`material/content/${materialId}/dashboard`)

export const getMaterialsByCatalogs = (data: any) =>
  http.httpCore.post('material/by-catalogs', data)
