import { createTheme } from '@mui/material/styles'

// assets
import InterRegularTTF from './assets/fonts/Inter-Regular.ttf'
import AvertaRegular from './assets/fonts/Averta-Regular.woff2'
import AvertaSemiBold from './assets/fonts/Averta-SemiBold.woff2'
import AvertaBold from './assets/fonts/Averta-Bold.woff2'

export const customTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1903,
    },
  },
  typography: {
    allVariants: {
      color: '#4E5D78',
    },
    fontFamily: 'Averta, sans-serif',
    h1: {
      fontWeight: 600,
      fontSize: '20px',
      lineHeight: '130%',
    },
    h2: {
      fontWeight: 700,
      fontSize: '32px',
      lineHeight: '130%',
    },
    subtitle1: {
      fontWeight: 400,
      lineHeight: '130%',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Inter';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('Inter'), local('Inter-Regular'), url(${InterRegularTTF}) format("truetype");
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
        @font-face {
          font-family: 'Averta';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('Averta'), local('Averta-Regular'), url(${AvertaRegular}) format("woff2");
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
        @font-face {
          font-family: 'Averta';
          font-style: normal;
          font-display: swap;
          font-weight: 600;
          src: local('Averta'), local('Averta-SemiBold'), url(${AvertaSemiBold}) format("woff2");
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
        @font-face {
          font-family: 'Averta';
          font-style: normal;
          font-display: swap;
          font-weight: 700;
          src: local('Averta'), local('Averta-Bold'), url(${AvertaBold}) format("woff2");
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        },
      `,
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 0,
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          height: '100%',
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          padding: '14px 24px',
          boxShadow: '0px 30px 60px rgba(32, 56, 85, 0.15)',
          borderRadius: 8,
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: '#ffffff',
        },
        root: {
          boxShadow: 'none',
          height: 80,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 0,
          textTransform: 'none',
        },
      },
    },
  },
  palette: {
    background: {
      default: '#f3f3f3',
    },
    primary: {
      main: '#2e7d32',
      light: 'rgb(150 223 154 / 50%)',
      dark: '#49854c',
    },
    secondary: {
      main: '#E88431',
      light: '#ff7543',
      dark: '#F24C43',
    },
    text: {
      primary: '#4E5D78',
      secondary: '#2A9D8F',
      disabled: 'rgba(78, 93, 120, 0.8)',
    },
  },
})
