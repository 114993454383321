import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'
// import { uploadFile } from '../../api/requests/file'
import { getArrangements } from '../../api/requests/arrangement'

export const fetchArrangements = createAsyncThunk(
  '/arrangement',
  async (params: any, thunkAPI) => {
    try {
      const response = await getArrangements(params)
      return response.data.data
    } catch (e) {
      return thunkAPI.rejectWithValue('Не удалось загрузить уроки')
    }
  }
)