import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { fetchContentLearnMaterials } from './learnMaterialContentThunks'
import ILearnMaterialContent from './learnMaterialContentTypes'

interface LearnStateContent {
  learnMaterialsContent: ILearnMaterialContent[]
  pageMaterialContent: number
  pageSizeMaterialContent: number
  totalPagesMaterialContent: number
  isLoading: boolean
  error: string
}

const initialState: LearnStateContent = {
  learnMaterialsContent: [],
  pageMaterialContent: 0,
  pageSizeMaterialContent: 20,
  totalPagesMaterialContent: 1,
  isLoading: false,
  error: '',
}

export const learnMaterialsSlice = createSlice({
  name: 'learn-materials',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchContentLearnMaterials.fulfilled.type]: (
      state,
      action: PayloadAction<any>
    ) => {
      state.isLoading = false
      state.error = ''
      state.learnMaterialsContent = action.payload.materialContents
      state.pageMaterialContent = action.payload.page
      state.pageSizeMaterialContent = action.payload.pageSize
      state.totalPagesMaterialContent = action.payload.totalPages
    },
    [fetchContentLearnMaterials.pending.type]: (state) => {
      state.isLoading = true
    },
    [fetchContentLearnMaterials.rejected.type]: (
      state,
      action: PayloadAction<string>
    ) => {
      state.isLoading = false
      state.error = action.payload
      state.learnMaterialsContent = []
    },
  },
})

export default learnMaterialsSlice.reducer
