import { createSlice, PayloadAction } from '@reduxjs/toolkit'

// types
import { AUTH, IAuth, IAuthUser, IUserPosition } from './authTypes'

const initialState: IAuth = {
  authUser: null,
  userPositions: [],
  access: true,
  accessDesc: '',
  closedLogin: false,
  checkingLogin: false,
}

const authSlice = createSlice({
  name: AUTH,
  initialState,
  reducers: {
    setAuthUser: (state, action: PayloadAction<IAuthUser>) => {
      state.authUser = action.payload
    },

    setUserPositions: (state, action: PayloadAction<IUserPosition[]>) => {
      state.userPositions = action.payload
    },

    setAccess: (state, action: PayloadAction<boolean>) => {
      state.access = action.payload
    },
    setAccessDesc: (state, action: PayloadAction<string>) => {
      state.accessDesc = action.payload
    },
    setCheckingLogin: (state, action: PayloadAction<boolean>) => {
      state.checkingLogin = action.payload
    },
    setClosedLogin: (state, action: PayloadAction<boolean>) => {
      state.closedLogin = action.payload
    },
  },
})

export default authSlice.reducer
export const {
  setClosedLogin,
  setCheckingLogin,
  setAuthUser,
  setUserPositions,
  setAccess,
  setAccessDesc,
} = authSlice.actions
