import { customTheme } from './theme'
import { ThemeProvider } from '@mui/material/styles'
import { OIDCAuthIFrame } from '@proapteka/oidc-auth-iframe'
import { authController } from './api/instance'
import Loader from './components/UI/Loader/loader'
import { Suspense, lazy } from 'react'
const PrivateRouter = lazy(() => import('./routes/PrivateRouter'))
const Layout = lazy(() => import('./components/Layout/Layout'))

function App() {
  return (
    <ThemeProvider theme={customTheme}>
      <OIDCAuthIFrame
        authControllerInstance={authController}
        preloader={<Loader />}
      >
          <Suspense fallback={<Loader />}>
            <Layout>
              <PrivateRouter />
            </Layout>
          </Suspense>
      </OIDCAuthIFrame>
    </ThemeProvider>
  )
}

export default App
