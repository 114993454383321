import { useEffect, useRef, useState } from 'react'

type EventNamesType = 'error' | 'authStatus' | 'formVisible'

type AuthIframeProps = {
  authControllerInstance: {
    iframeElement: HTMLIFrameElement
    addEventListener(eventName: EventNamesType, callback: any): void
  }
}

export const AuthIframe = (props: AuthIframeProps) => {
  const { authControllerInstance } = props
  const [isShowForm, setShowForm] = useState(false)
  const formContainerRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (formContainerRef.current === null) return
    if ('iframeElement' in authControllerInstance)
      formContainerRef.current?.appendChild(
        authControllerInstance.iframeElement
      )
    authControllerInstance.addEventListener('formVisible', (visible: boolean) =>
      setShowForm(visible)
    )
  }, [])

  return (
    <div
      ref={formContainerRef}
      style={{
        position: 'fixed',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        margin: 'auto',
        width: '100vw',
        height: '100vh',
        zIndex: 99999,
        background: '#00000070',
        backdropFilter: 'blur(2px)',
        display: isShowForm ? 'flex' : 'none',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    />
  )
}
