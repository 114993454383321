import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { fetchLearnMaterials } from './learnMaterialThunks'
import ILearnMaterial from './learnMaterialsTypes'

interface LearnState {
  learnMaterials: ILearnMaterial[]
  pageMaterial: number
  pageSizeMaterial: number
  totalPagesMaterial: number
  isLoading: boolean
  error: string
}

const initialState: LearnState = {
  learnMaterials: [],
  pageMaterial: 0,
  pageSizeMaterial: 20,
  totalPagesMaterial: 1,
  isLoading: false,
  error: '',
}

export const learnMaterialsSlice = createSlice({
  name: 'learn-materials',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(fetchLearnMaterials.fulfilled, (state, action) => {
      state.isLoading = false
      state.error = ''
      state.learnMaterials = action.payload.materials
      state.pageMaterial = action.payload.page
      state.pageSizeMaterial = action.payload.pageSize
      state.totalPagesMaterial = action.payload.totalPages
    })
    builder.addCase(fetchLearnMaterials.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(
      fetchLearnMaterials.rejected,
      (state, action: PayloadAction<any>) => {
        state.isLoading = false
        state.error = action.payload
        state.learnMaterials = []
        state.pageMaterial = 0
        state.totalPagesMaterial = 0
      }
    )
  },
  // extraReducers: {
  //   [fetchLearnMaterials.fulfilled.type]: (
  //     state,
  //     action: PayloadAction<any>
  //   ) => {
  // state.isLoading = false
  // state.error = ''
  // state.learnMaterials = action.payload.materials
  // state.pageMaterial = action.payload.page
  // state.pageSizeMaterial = action.payload.pageSize
  // state.totalPagesMaterial = action.payload.totalPages
  //   },
  //   [fetchLearnMaterials.pending.type]: (state) => {
  //     state.isLoading = true
  //   },
  //   [fetchLearnMaterials.rejected.type]: (
  //     state,
  //     action: PayloadAction<string>
  //   ) => {
  // state.isLoading = false
  // state.error = action.payload
  // state.learnMaterials = []
  // state.pageMaterial = 0
  // state.totalPagesMaterial = 0
  //   },
  // },
})

export const learnMaterialsReducer = learnMaterialsSlice.reducer
