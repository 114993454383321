import axios from 'axios'
import qs from 'qs'
import { apiUrl } from '../env.config'
import { store } from '../store/store'
import { OIDCAuthController } from '@proapteka/oidc-auth-controller'

axios.defaults.withCredentials = true

const httpCore = axios.create({
  baseURL: apiUrl,
  timeout: 60 * 1000,
  paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
  headers: {
    Accept: 'application/ld+json',
    'Content-Type': 'application/json; charset=utf-8',
    'Cache-Control': 'no-cache',
    'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, DELETE, PUT',
    'Access-Control-Allow-Headers':
      'access-control-allow-headers,access-control-allow-methods,access-control-expose-headers,cache-control,Authorization,DNT,User-Agent,X-Requested-With,If-Modified-Since,Cache-Control,Content-Type,Range,X-Real-Ip,X-Api-Key,X-Jwt-Token,Accept',
    'Access-Control-Expose-Headers':
      'access-control-allow-headers,access-control-allow-methods,access-control-expose-headers,cache-control,Authorization,DNT,User-Agent,X-Requested-With,If-Modified-Since,Cache-Control,Content-Type,Range,X-Real-Ip,X-Api-Key,X-Jwt-Token,Accept',
  },
})

export const authController = new OIDCAuthController({
  Axios: httpCore,
})

// export const authController = new OAuthController(httpCore)

const httpCoreMultipart = axios.create({
  baseURL: apiUrl,
  timeout: 60 * 1000,
  headers: {
    'Content-Type': 'multipart/form-data',
    Accept: 'application/json',
  },
})

// @ts-ignore
httpCore.interceptors.request.use((config) => {
  if (!config.headers) return
  return config
})

httpCore.interceptors.response.use(
  (response) => response,
  (error) => {
    return error
  }
)

export default { httpCore, httpCoreMultipart }
